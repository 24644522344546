<template>
  <div class="list-info">
    <top-bar :title="'人大信箱'" :left="true"></top-bar>
    <div>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getDataList"
        v-if="dataList.length > 0"
        offset="10"
      >
        <div
          class="page-view page-content-view"
          v-for="item in dataList"
          :key="item.id"
          @click="getInfo(item.id,item.checkUser?'1':'0')"
        >
          <div class="title-left">
            {{ item.letterTitle }}
          </div>
          <span class="title-right" v-if="item.checkUser"> 已反馈 </span>
          <span class="title-right" style="color: #e61313" v-else
            >等待反馈</span
          >
          <div class="content-text">{{ item.createTime }}</div>
        </div>
      </van-list>
      <van-empty description="没有数据哦" v-if="dataList.length < 1" />
    </div>
  </div>
</template>
<script>
import topBar from "@/components/topBar/topBar";
import { mapMutations } from "vuex";
export default {
  components: {
    topBar,
  },
  data() {
    return {
      page: 0,
      limit: 10,
      dataList: [],
      orgId: "",
      loading: false, //标记是否请求数据
      haveMore: false,
      activeIndex: false,
      finished: false
    };
  },
  methods: {
    ...mapMutations,
    getInfo(id,isCheck) {
      this.$router.push({ path: "/letter-add", query: { id: id,isCheck: isCheck } });
    },
    getDataList() {
      this.page++;
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl("/wxapp/future/letter/list"),
        method: "post",
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          orgId: this.$orgId,
          userId: this.$globalData.userInfo.userId,
        }),
      }).then(
        ({ data }) => {
          if (data.code == 0) {
            this.totalCount = data.page.totalCount;
            if (this.dataList.length == data.page.totalCount) {
              this.finished = true;
            }
            this.dataList = this.dataList.concat(data.page.list);
            // 加载状态结束
            this.loading = false;
            this.$toast.clear();
          }
          this.$toast.clear();
        },
        (err) => {
          this.$toast.clear();
        }
      );
    },
  },
  created() {
    this.$toast.loading({
      duration: 0,
      message: "加载中...",
      forbidClick: true,
    });
    this.getDataList();
  },
};
</script>
<style lang="scss" scoped>
.list-info {
  margin: 30px;
}
.page-view {
  background-color: white;
}
.page-content-view {
  margin-top: 30px;
  padding: 20px 40px;
  font-size: 30px;
  border-radius: 10px;
}
.title-left {
  float: left;
  color: #333333;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
}
.title-right {
  float: right;
  color: #1e7dff;
  font-size: 24px;
  font-family: PingFang SC;
}
.content-text {
  margin-top: 60px;
  color: #666666;
  font-size: 24px;
  font-family: PingFang SC;
}
</style>
