import { render, staticRenderFns } from "./letter.vue?vue&type=template&id=622a603c&scoped=true&"
import script from "./letter.vue?vue&type=script&lang=js&"
export * from "./letter.vue?vue&type=script&lang=js&"
import style0 from "./letter.vue?vue&type=style&index=0&id=622a603c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "622a603c",
  null
  
)

export default component.exports